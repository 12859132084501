/* ==========================================================================
Environment
========================================================================== */

"use strict";

var env = "development";

/* ==========================================================================
Active Theme
========================================================================== */

var activeTheme = "azur";

/* ==========================================================================
Theme Colors
========================================================================== */

var themes = {
    core: {
        primary: "#8c8cf9",
        secondary: "#7F00FF",
        accent: "#ad5cff",
    },
    purple: {
        primary: "#837FCB",
        secondary: "#5551a8",
        accent: "#6A3144",
    },
    teal: {
        primary: "#00D1B2",
        secondary: "#7F00FF",
        accent: "#536dfe",
    },
    green: {
        primary: "#00b289",
        secondary: "#00b289",
        accent: "#00b289",
    },
    azur: {
        primary: "#335483",
        secondary: "#0084c4",
        accent: "#00D1B2",
    },
    blue: {
        primary: "#0f7cff",
        secondary: "#00edb1",
        accent: "#0062dd",
    },
    night: {
        primary: "#4A00E0",
        secondary: "#23a6d5",
        accent: "#8E2DE2",
    },
    yellow: {
        primary: "#f5c62c",
        secondary: "#8c8cf9",
        accent: "#fe8c00",
    },
    orange: {
        primary: "#f83600",
        secondary: "#8c8cf9",
        accent: "#fe8c00",
    },
    red: {
        primary: "#ff5555",
        secondary: "#ff3131",
        accent: "#1ddba5",
    },
};